import tinymce from 'tinymce';

import 'tinymce/icons/default';
import 'tinymce/themes/silver';
import 'tinymce/models/dom';
import 'tinymce/plugins/link';
import 'tinymce/skins/ui/oxide/skin.min.css';
import 'tinymce-i18n/langs/nl';

export function render (selector) {
    tinymce.init({
        selector: selector,
        menubar: false,
        promotion: false,
        language: 'nl',
        plugins: 'link',
        toolbar: 'bold italic underline | link | variables',
        content_style: 'body { font-family: sans-serif; }',
        skin: false,
        content_css: false,
        statusbar: false,
        setup: function (editor) {
            editor.ui.registry.addMenuButton('variables', {
                text: 'Variabelen',
                icon: 'ai-prompt',
                fetch: (callback) => {
                    const items = [
                        {
                            type: 'menuitem',
                            text: 'Aanhef',
                            onAction: () => editor.insertContent('%aanhef%')
                        },
                        {
                            type: 'menuitem',
                            text: 'Voornaam',
                            onAction: () => editor.insertContent('%voornaam%')
                        },
                        {
                            type: 'menuitem',
                            text: 'Tussenvoegsel',
                            onAction: () => editor.insertContent('%tussenvoegsel%')
                        },
                        {
                            type: 'menuitem',
                            text: 'Achternaam',
                            onAction: () => editor.insertContent('%achternaam%')
                        },
                        {
                            type: 'menuitem',
                            text: 'Volledige achternaam',
                            onAction: () => editor.insertContent('%volledige_achternaam%')
                        },
                        {
                            type: 'menuitem',
                            text: 'Volledige naam',
                            onAction: () => editor.insertContent('%volledige_naam%')
                        },
                        {
                            type: 'separator',
                        },
                        {
                            type: 'menuitem',
                            text: 'Dossiernummer',
                            onAction: () => editor.insertContent('%dossiernummer%')
                        },
                    ];
                    callback(items);
                }
            });
        }
    })
}
