import './bootstrap';
import './toggle';
import './accordion';
import './anchor';
import './navbarHeight';
import './FieldToggle';

import { Livewire, Alpine } from '../../vendor/livewire/livewire/dist/livewire.esm';

window.Alpine = Alpine;
window.Livewire = Livewire;

Livewire.start()

import './livewireValidation';
import './livewireRebind';
import AOS from "aos";
import DatePicker from "@/DatePicker";
import FieldToggle from "@/FieldToggle";
import SelectText from "@/SelectText";
import Chart from "@/Chart";
import * as tinymce from './editor';

window.tinymce = tinymce;

AOS.init({
  duration: 700
});

document.addEventListener("DOMContentLoaded", function () {
    FieldToggle.initAll('[data-toggle="field-toggle"]');
    DatePicker.initAll('[data-toggle="date-picker"]');
    SelectText.initAll('[data-toggle="select-text"]');
    Chart.initAll('[data-chart]');
});
