import DatePicker from "@/DatePicker";

window.Livewire.hook('commit', ({ component, commit, respond, succeed, fail }) => {
    succeed(({ snapshot, effect }) => {
        queueMicrotask(() => {
            // As the DOM has changed, let's bind the datepicker on the new elements too
            DatePicker.initAll('[data-toggle="date-picker"]');
        })
    })
})
