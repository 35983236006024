import { Collapse, initTWE, } from 'tw-elements';

initTWE({ Collapse });

document.querySelectorAll('.accordion button').forEach((button) => {
    button.addEventListener('click', () => {
        setTimeout(() => {
            let rect = button.getBoundingClientRect();
            let win = button.ownerDocument.defaultView;
            let top  = rect.top + win.pageYOffset - 100;

            document.documentElement.scrollTo({
                top: top,
                behavior: "smooth",
            });
        }, 300);
    });
});
